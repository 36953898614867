var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-layout-sider",
        {
          staticClass: "default-style",
          class: ["side-menu", "beauty-scroll", _vm.isMobile ? null : ""],
          attrs: {
            theme: _vm.sideTheme,
            collapsible: _vm.collapsible,
            trigger: null,
          },
          model: {
            value: _vm.collapsed,
            callback: function ($$v) {
              _vm.collapsed = $$v
            },
            expression: "collapsed",
          },
        },
        [
          _c("i-menu", {
            staticClass: "menu",
            attrs: {
              theme: _vm.theme,
              collapsed: _vm.collapsed,
              options: _vm.menuData,
            },
            on: { select: _vm.onSelect },
          }),
        ],
        1
      ),
      _c("subMenu"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }