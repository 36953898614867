<template>
  <div class="header">
    <div class="header-card" size="small">
      <div class="header-container flex-just-between flex-align-center">
        <div class="header-name">
          <span class="company-name font14 flex-align-center">
            <!-- 山东数昶信息技术有限公司 -->
            {{ tenant ? tenant.tenantName : '' }}
          </span>
        </div>
        <div class="header-search flex-align-center">
          <SearchPopover ref="searchPopover" :search-value.sync="searchValue">
            <template slot="search">
              <span class="search-box" @click="openSearch">
                <a-input
                  v-model="searchValue"
                  size="small"
                  :placeholder="$refs.searchPopover && $refs.searchPopover.intPlaceholder"
                  @keydown="confirmSearch"
                >
                  <!-- <a-icon slot="suffix" class="suffix-search" type="search" /> -->
                  <template slot="suffix">
                    <svg class="iconpark-icon suffix-search" @click="$refs.searchPopover.search()"><use href="#search" /></svg>
                  </template>
                </a-input>
              </span>
            </template>
          </SearchPopover>
          <HistoryPopover>
            <template slot="icon">
              <a-tooltip slot="icon" placement="bottom">
                <template slot="title">
                  近期访问
                </template>
                <span class="icon-box ml-18">
                  <svg class="iconpark-icon icon-bell flex-center"><use href="#zu1233" /></svg>
                </span>
              </a-tooltip>
            </template>
          </HistoryPopover>
          <BellPopover ref="BellPopover">
            <a-tooltip slot="icon" placement="bottom">
              <template slot="title">
                站内信
              </template>
              <template>
                <span class="icon-box" @click="$refs.BellPopover.show()">
                  <svg class="iconpark-icon icon-bell flex-center"><use href="#zu1234" /></svg>
                </span>
              </template>
            </a-tooltip>
          </BellPopover>
          <!-- 意见反馈 -->
          <!-- <a-tooltip placement="bottom">
            <template slot="title">
              意见反馈
            </template>
            <span class="icon-box">
              <a-icon class="icon" type="question-circle" theme="filled" />
            </span>
          </a-tooltip> -->
          <a-popover
            v-model="feedVisible"
            overlay-class-name="function-popover"
            placement="topRight"
            trigger="click"
            :get-popup-container="(triggerNode) => triggerNode.parentNode"
          >
            <template slot="content">
              <div class="function-list dont-copy">
                <div class="function-item" @click="addFeedBack">
                  <svg class="iconpark-icon cutton"><use href="#tips-one" /></svg>
                  问题反馈
                </div>
                <div class="function-item" @click="$emit('showGuide');feedVisible=false">
                  <svg class="iconpark-icon cutton"><use href="#tips-6bi0hmob" /></svg>
                  配置导览
                </div>
              </div>
            </template>
            <div class="icon-box">
              <a-tooltip slot="icon" placement="bottom">
                <template slot="title">
                  问题反馈
                </template>
                <a-icon class="icon icon-bell function flex-center" type="question-circle" theme="filled" />
              </a-tooltip>
            </div>
          </a-popover>
          <!-- 头像 -->
          <AvaterPopover ref="avaterPopover" :account-info="accountInfo">
            <template slot="avaterPopover">
              <div class="info-avatar" @click="avatarUser">
                <a-tooltip
                  placement="bottom"
                  :mouse-enter-delay="0.2"
                  overlay-class-name="info-tooltip"
                  :get-popup-container="(triggerNode) => triggerNode.parentNode"
                >
                  <template slot="title">
                    个人信息
                  </template>
                  <CbAvater
                    :on-job-id="accountInfo.onJobId"
                    :avater-name="accountInfo.staffName?accountInfo.staffName : accountInfo.userName ? accountInfo.userName : ''"
                    size="30"
                    :show-user-name="false"
                  />
                </a-tooltip>
              </div>
            </template>
          </AvaterPopover>
          <FeedbackModal ref="feedbackModal" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { mapMutations } from 'vuex'
export default {
  name: 'Header',
  components: {
    AvaterPopover: () => import('./avaterPopover/index.vue'),
    BellPopover: () => import('./bellPopover/index.vue'),
    FeedbackModal: () => import('./feedbackModal/index.vue'),
    CbAvater: () => import('@/components/CbAvater/theAvater.vue'),
    HistoryPopover: () => import('./historyPopover/index.vue'),
    SearchPopover: () => import('./searchPopover/index.vue')
  },
  props: {},
  data() {
    return {
      searchValue: '',
      feedVisible: false
    }
  },
  computed: {
    ...mapGetters('account', ['tenant', 'accountInfo'])
  },
  mounted() {
    this.$forceUpdate()
  },
  methods: {
    ...mapMutations('setting', ['setSubMenuData', 'setLoginType']),
    // 获取账号信息
    confirmSearch(e) {
      if (e.code === 'Enter') {
        this.$refs.searchPopover.search()
      }
    },
    // 打开头像气泡卡片
    async avatarUser() {
      this.$refs.avaterPopover.open()
    },
    // 意见反馈
    addFeedBack() {
      this.feedVisible = false
      this.$refs.feedbackModal.open()
    },
    openSearch() {
      this.$refs.searchPopover.searchVisible = true
    }
  }
}
</script>

<style lang="less" scoped>
.header {
  .header-card {
    width: 100%;
    height: 48px;
  }
  .header-container {
    width: 100%;
    height: 48px;
    padding: 0 12px 0 14px;
    // background: @sc-grey-100;
    background-image: linear-gradient(to right , #2D457A, #1C2D51);
    .header-name {
      color: #fff;
      .font-size(14px);
    }
    .header-search {
      .search-box {
        padding-right: 2px;
        /deep/ input.ant-input {
          background: rgba(255,255,255, .10);
          color:#fff;
          width: 372px;
          border: none;
          height: 32px;
          .font-size(14px);
          &::-webkit-input-placeholder {
            color: rgba(255, 255, 255, 0.8);
          }
        }
        .suffix-search {
          color: #fff;
          &:hover{
            cursor: pointer;
          }
        }
      }
      .icon-box {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 32px;
        width: 32px;
        border-radius: 50%;
        cursor: pointer;
        background: rgba(255,255,255,.10);
        margin: 0 8px;
        .icon-bell {
          color: #fff;
          width: 20px;
          height: 20px;
          &:hover {
            color: rgba(255, 255, 255, .8);
          }
        }
        .function {
          font-size: 18px;
          color: rgba(255, 255, 255, .8);
          &:hover{
            color: rgba(255, 255, 255, .6) !important;
          }
        }
      }
      .ml-18{
        margin-left:18px;
      }
      .info-avatar {
        width: 30px;
        height: 30px;
        // line-height: 30px;
        margin: 0 8px 0 12px;
        border-radius: 50%;
        cursor: pointer;
        position: relative;
      }
      .avater-text {
        width: 30px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        border-radius: 50%;
        background-color: @primary-color;
        color: #fff;
      }
    }
  }
}
/deep/ .img_box:hover {
  border: 1px solid #fff;
}
.function-list {
  .function-item {
    padding: 9px 14px;
    cursor: pointer;
    width: 106px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .cutton {
      width: 17px;
      height: 17px;
      margin-right: 4px;
    }
  }
}
.iconpark-icon{
  width: 16px;
  height: 16px;
}
</style>
<style lang="less">
.info-tooltip{
  width: max-content;
}
</style>
