var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a-layout-header", { class: [_vm.headerTheme, "admin-header"] }, [
    _c(
      "div",
      { class: ["admin-header-wide", _vm.layout, _vm.pageWidth] },
      [
        _vm.isMobile || _vm.layout === "head"
          ? _c("router-link", {
              class: ["logo", _vm.isMobile ? null : "pc", _vm.headerTheme],
              attrs: { to: "/" },
            })
          : _vm._e(),
        _vm.isMobile
          ? _c("a-divider", { attrs: { type: "vertical" } })
          : _vm._e(),
        _vm.layout !== "head"
          ? _c("a-icon", {
              staticClass: "trigger",
              attrs: { type: _vm.collapsed ? "menu-unfold" : "menu-fold" },
              on: { click: _vm.toggleCollapse },
            })
          : _vm._e(),
        _vm.layout !== "side" && !_vm.isMobile
          ? _c(
              "div",
              {
                staticClass: "admin-header-menu",
                style: "width: " + _vm.menuWidth + ";",
              },
              [
                _c("i-menu", {
                  staticClass: "head-menu",
                  attrs: {
                    theme: _vm.headerTheme,
                    mode: "horizontal",
                    options: _vm.menuData,
                  },
                  on: { select: _vm.onSelect },
                }),
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          { class: ["admin-header-right", _vm.headerTheme] },
          [
            _c("header-search", {
              staticClass: "header-item",
              on: {
                active: function (val) {
                  return (_vm.searchActive = val)
                },
              },
            }),
            _c(
              "a-tooltip",
              {
                staticClass: "header-item",
                attrs: { title: "帮助文档", placement: "bottom" },
              },
              [
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://iczer.gitee.io/vue-antd-admin-docs/",
                      target: "_blank",
                    },
                  },
                  [_c("a-icon", { attrs: { type: "question-circle-o" } })],
                  1
                ),
              ]
            ),
            _c("header-notice", { staticClass: "header-item" }),
            _c("header-avatar", { staticClass: "header-item" }),
            _c(
              "a-dropdown",
              { staticClass: "lang header-item" },
              [
                _c(
                  "div",
                  [
                    _c("a-icon", { attrs: { type: "global" } }),
                    _vm._v(" " + _vm._s(_vm.langAlias) + " "),
                  ],
                  1
                ),
                _c(
                  "a-menu",
                  {
                    attrs: { slot: "overlay", "selected-keys": [_vm.lang] },
                    on: {
                      click: function (val) {
                        return _vm.setLang(val.key)
                      },
                    },
                    slot: "overlay",
                  },
                  _vm._l(_vm.langList, function (lang) {
                    return _c("a-menu-item", { key: lang.key }, [
                      _vm._v(_vm._s(lang.key.toLowerCase() + " " + lang.name)),
                    ])
                  }),
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }