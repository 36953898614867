<template>
  <div class="menu">
    <ul class="menuBox">
      <template v-for="(key, index) in openMenu">
        <li
          v-if="showMenu(key.fullPath)"
          :key="`menuItem${index}`"
          class="menuItem"
          :class="{ 'active': $route.matched[1].path === key.fullPath }"
          @click="changeMenu(key, false, index)"
        >
          <!-- <svg class="iconpark-icon"><use :href="`${key.meta.icon}`" /></svg> -->
          <svg class="iconpark-icon"><use :href="menuIcon(key.fullPath, key.meta.icon)" /></svg>
          <div class="menuName">{{ key.meta.subName }}</div>
        </li>
      </template>
    </ul>
    <div v-if="globalAuther" class="bottomMune">
      <a-tooltip placement="right">
        <template slot="title">
          {{ globalRouteInfo.meta.subName }}
        </template>
        <span>
          <svg class="iconpark-icon globalSetting" @click="toGlobal"><use :href="globalRouteInfo.meta.icon" /></svg>
        </span>
      </a-tooltip>
    </div>
    <a-popover
      v-if="foldMenu.length > 0"
      v-model="visible"
      overlay-class-name="aaa"
      title=""
      trigger="click"
      placement="rightTop"
    >
      <div slot="content" class="foldMenuBox">
        <ul class="foldMenuBox">
          <li
            v-for="(key, index) in foldMenu"
            :key="`foldMenuItem${index}`"
            class="foldMenuItem"
            :class="{ 'active': $route.matched[1].path === key.fullPath }"
            @click="changeMenu(key, true, index)"
          >
            <svg class="iconpark-icon foldMenuIcon"><use :href="`${key.meta.icon}`" /></svg>
            <span class="foldMenuName">{{ key.meta.subName }}</span>
          </li>
        </ul>
      </div>
      <div class="hideMenu">
        ...
      </div>
    </a-popover>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { mapGetters } from 'vuex'
export default {
  name: 'Imenu',
  props: {
    options: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data() {
    return {
      visible: false,
      hideMenuList: [
        {
          name: '全局设置',
          path: '/globalSetting'
        }
      ],
      globalAuther: false,
      // 全局设置的路由信息
      globalRouteInfo: {}
    }
  },
  computed: {
    ...mapGetters('account', ['accountInfo']),
    copyOptions() {
      const copyData = JSON.parse(JSON.stringify(this.options))
      return copyData.filter(item => {
        let hasRepet = true
        for (let i = 0; i < this.hideMenuList.length; i++) {
          const key = this.hideMenuList[i]
          if (item.fullPath === key.path) {
            if (key.path === '/globalSetting') {
              this.globalRouteInfo = item
              this.globalAuther = true
            }
            hasRepet = false
            break
          }
        }
        return hasRepet
      })
    },
    foldMenu() {
      const { showMenuNum } = this.$store.state.setting
      return JSON.parse(JSON.stringify(this.copyOptions || [])).splice(showMenuNum)
    },
    openMenu() {
      const { showMenuNum } = this.$store.state.setting
      return JSON.parse(JSON.stringify(this.copyOptions || [])).splice(0, showMenuNum)
    }
  },
  watch: {
    $route() {
      const tenant = JSON.parse(localStorage.getItem(process.env.VUE_APP_TENANT_KEY))
      if (!tenant || !tenant.tenantId) {
        return
      }
      const recentVisitsStr = localStorage.getItem(process.env.VUE_APP_RECENTVISITS)
      const recentVisits = recentVisitsStr ? JSON.parse(recentVisitsStr) || {} : {}
      const routeObj = {
        // 一级的菜单名称
        firstRouteName: '',
        // 二级的菜单名称
        secondRouteName: '',
        // 一级的图标
        iconPath: '',
        // 跳转的全路径
        routePath: this.$route.fullPath
      }
      this.options.forEach((item, index) => {
        if (this.$route.matched[1].path === item.fullPath) {
          this.changeMenu(this.options[index], false, 0)
          routeObj.firstRouteName = this.options[index].meta.subName
          routeObj.iconPath = this.options[index].meta.icon
          // 存在二级路由   注意：二级路由的考勤存在分组的概念
          if (this.options[index].children) {
            for (let i = 0; i < this.options[index].children.length; i++) {
              const element = this.options[index].children[i]
              // 二级分组的情况
              if (element.children && element.children.length > 0) {
                for (let j = 0; j < element.children.length; j++) {
                  const k = element.children[j]
                  if (k.fullPath === this.$route.fullPath.split('?')[0]) {
                    routeObj.secondRouteName = k.meta.subName
                    break
                  }
                }
              } else {
                if (element.fullPath === this.$route.fullPath.split('?')[0]) {
                  routeObj.secondRouteName = element.meta.subName
                  break
                }
              }
            }
          }
        }
      })
      if (recentVisits[tenant.tenantId]) {
        recentVisits[tenant.tenantId].unshift(routeObj)
      } else {
        recentVisits[tenant.tenantId] = [routeObj]
      }
      // 只保留最近三条
      if (recentVisits[tenant.tenantId].length > 3) {
        recentVisits[tenant.tenantId].length = 3
      }
      this.setRecentVisits(recentVisits || null)
      localStorage.setItem(process.env.VUE_APP_RECENTVISITS, JSON.stringify(recentVisits || ''))
    }
  },
  mounted() {
    this.options.forEach((item, index) => {
      if (this.$route.matched[1].path === item.fullPath) {
        this.changeMenu(this.options[index], false, 0)
      }
    })
  },
  methods: {
    ...mapMutations('setting', ['setSubMenuData', 'setMenuData', 'setRecentVisits']),
    showMenu(fullPath) {
      for (let i = 0; i < this.hideMenuList.length; i++) {
        if (this.hideMenuList[i].path === fullPath) {
          return false
        }
      }
      return true
    },
    changeMenu(key, isFoldMenuItem, index) {
      if (key.children) {
        this.setSubMenuData(key.fullPath)
      } else {
        this.setSubMenuData('')
        if (key.path === this.$route.path) {
          return
        }
        this.$router.push(key.fullPath)
      }
      // 如果当前点击的菜单是再隐藏菜单中的，则修改菜单数据的顺序
      if (isFoldMenuItem) {
        const { showMenuNum } = this.$store.state.setting
        const options = JSON.parse(JSON.stringify(this.options || []))
        options.splice(showMenuNum + index, 1)
        options.splice(showMenuNum, 0, key)
        const showMenuLastPath = this.openMenu[this.openMenu.length - 1].fullPath
        const originPath = key.fullPath
        let showMenuIndex = 0
        let FoldMenuIndex = 0
        for (let i = 0; i < this.options.length; i++) {
          const item = this.options[i]
          if (item.fullPath === showMenuLastPath) {
            showMenuIndex = i
          }
          if (item.fullPath === originPath) {
            FoldMenuIndex = i
          }
        }
        const copyOptions = JSON.parse(JSON.stringify(this.options))
        const showMenuData = copyOptions[showMenuIndex]
        const FoldMenuData = copyOptions[FoldMenuIndex]
        copyOptions[showMenuIndex] = FoldMenuData
        copyOptions[FoldMenuIndex] = showMenuData
        this.setMenuData(copyOptions)
        const originData = JSON.parse(localStorage.getItem(process.env.VUE_APP_PERMISSIONS_KEY))
        // const showMenuIndex = showMenuNum - 1
        // const FoldMenuIndex = index
        this.exchange(originData[0].children, showMenuIndex + 1, FoldMenuIndex + 1)
        console.log('originData--------', originData)
        localStorage.setItem(process.env.VUE_APP_ROUTES_KEY, JSON.stringify(originData))
        localStorage.setItem(process.env.VUE_APP_PERMISSIONS_KEY, JSON.stringify(originData))
        this.visible = false
      }
    },
    // 同一个数组两个数据项交换位置
    exchange(arr, x, y) {
      arr.splice(x - 1, 1, ...arr.splice(y - 1, 1, arr[x - 1]))
      console.log('xxxxxxxxxxxxxxxxx', arr)
      return arr
    },
    toGlobal() {
      this.setSubMenuData('/globalSetting')
      this.$router.push('/globalSetting/globalSettingCompanyInfo')
    },
    menuIcon(fullPath, icon) {
      if (this.$route.matched[1].path === fullPath) {
        const arr = icon.split('-')
        arr[arr.length - 1] = 'B'
        const resIcon = arr.join('-')
        return resIcon
      } else {
        return icon
      }
    }
  }
}
</script>

<style lang="less" scoped>
.active {
  background: #172C58;
  .menuName {
     color: #fff !important;
  }
  .iconpark-icon{
    color: @sc-primary-100 !important;
  }
}
.menu {
  min-height: 100%;
  margin-top: 0 !important;
  padding-top: 14px;
  background: linear-gradient(180deg, #2D457A 0%, rgba(28,45,81,0.98) 100%);
}
.menuBox {
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .menuItem {
    width: 52PX;
    height: 52PX;
    box-sizing: border-box;
    color: @sc-grey-60;
    list-style: none;
    padding: 6PX 0;
    cursor: pointer;
    margin-bottom: 10PX;
    border-radius: 8PX;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    .menuName {
      font-size: 14PX;
      width: 100%;
      text-align: center;
      color: rgba(255,255,255,.8);
    }
    &:hover{
      .menuName {
        color: #fff;
      }
      .iconpark-icon{
        color: @sc-primary-100;
      }
    }
  }
}
.hideMenu {
  color: rgba(255,255,255, .8);
  text-align: center;
  cursor: pointer;
  font-size: 20PX;
  &:hover {
    color: #fff;
  }
}
.foldMenuBox {
  .foldMenuBox {
    padding: 0;
    color: @sc-grey-60;
    .foldMenuItem {
      list-style: none;
      margin-bottom: 10PX;
      &:last-child {
        margin-bottom: 0;
      }
      cursor: pointer;
      .foldMenuIcon{
        width: 16px;
        height: 16px;
        vertical-align: text-bottom;
      }
      .foldMenuName {
        margin-left: 6PX;
      }
    }
  }
}
.bottomMune{
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 26px;
  text-align: center;
  .globalSetting{
    width: 22px;
    height: 18px;
    opacity: 1;
    font-size: 18px;
    color: @sc-greyBg-100;
    &:hover{
      cursor: pointer;
      color: @sc-primary-100;
    }
  }
}
.iconpark-icon {
  width: 24px;
  height: 24px;
}
</style>
