<template>
  <a-drawer
    :visible="visible"
    :closable="false"
    :mask-closable="false"
    :mask="isPackUp"
    placement="bottom"
    @close="visible = false"
  >
    <div class="guide-drawer dont-copy">
      <div v-if="visible" class="hearder-view" @click="handelPackUp">
        <div>
          配置导览
        </div>
        <a-icon class="down-icon" :class="{ 'up-icon' : isPackUp }" type="down" />
      </div>
      <div class="guide-content guide-content-full" :class="{ 'unfold' : !isPackUp }">
        <!-- 展示的箭头 表明是选中项 -->
        <div class="active-block">
          <div class="dot" />
          <div class="arrows" />
        </div>
        <!-- 左侧菜单 -->
        <div>
          <ul
            class="guide-menu-view dont-copy"
            :style="{ transform: `translate(0, calc(78px - ${42 * currentIndex}px))` }"
          >
            <li
              v-for="(item, index) in menuList"
              :key="item.cardCode"
              :class="`menu-item-${diffValue(index)}`"
              @click="menuChange(index)"
            >
              <span class="dot-view" />
              <div class="name-view" :class="{ current: currentIndex === index }">
                {{ item.name }}
              </div>
            </li>
          </ul>
        </div>
        <!-- 右侧内容 -->
        <div class="guide-step">
          <!-- 右侧标题 -->
          <div class="step-title">
            <div>
              <div v-if="currentMenu.dependGroupName && currentMenu.dependGroupName.length" class="current-menu">
                <a-tooltip
                  :title="relationTip(currentMenu)"
                  trigger="hover"
                  placement="topLeft"
                  overlay-class-name="relation-tooltip"
                >
                  <span class="shortName">
                    <img src="~@/assets/img/global/info-circle-filled.png" alt="">
                    {{ currentMenu.shortName }}
                  </span>
                </a-tooltip>
              </div>
              <div v-else class="current-menu">
                {{ currentMenu.shortName }}
              </div>
              <div class="description">
                {{ currentMenu.description }}
              </div>
            </div>
            <div class="clase-view" @click="visible = false">
              <a-icon class="close-icon" type="close" />
            </div>
          </div>
          <!-- 卡片列表 -->
          <transition-group
            id="cardList"
            class="card-list dont-copy"
            tag="div"
            name="fade-list"
            :css="false"
            @before-enter="beforeEnter"
            @enter="enter"
            @after-enter="afterEnter"
          >
            <div
              v-for="(item, index) in currentCardList"
              :key="item.cardCode"
              class="card-item"
              :class="{
                'card-item-disabled' : item.disabled,
                'card-item-active' : item.cardCode === currentCardCode
              }"
              :data-delay="index * 100"
              @click="goPage(item)"
            >
              <div class="card-name">
                <div class="card-name-text">
                  <div class="sort-num">
                    {{ item.sortNum }}
                  </div>
                  <div class="card-title">
                    {{ item.title }}
                  </div>
                </div>
                <div v-if="!item.checkStatus" class="sort-warnning">
                  !
                </div>
                <div v-if="item.optional" class="sort-success">
                  可略过
                </div>
              </div>
              <!-- 根据描述长度判断什么时候弹窗内展示 -->
              <template v-if="item.subTitle && item.subTitle.length > 16">
                <a-tooltip
                  trigger="hover"
                  overlay-class-name="sub-title-tooltip"
                >
                  <div slot="title">
                    <div v-html="item.subTitle" />
                  </div>
                  <div class="sub-title">
                    {{ item.subTitle | replaceBr }}
                  </div>
                </a-tooltip>
              </template>
              <template v-else>
                <!-- 描述需要去掉br 用来展示 -->
                <div class="sub-title">
                  {{ item.subTitle | replaceBr }}
                </div>
              </template>
              <!-- 卡片内步骤的列表 -->
              <div class="step-list">
                <div v-for="step in item.cardStepList" :key="step.stepCode" :class="link ? 'step-item2' : 'step-item'">
                  <div v-if="link" class="stepImg">
                    <img :src="excelImg" alt="">
                  </div>
                  <div class="step-name">
                    {{ step.stepName }}
                  </div>
                  <div class="check-value" :class="{ 'check-status' : !step.checkStatus }">
                    {{ step.checkValue }}
                  </div>
                </div>
              </div>
            </div>
          </transition-group>
        </div>
      </div>
    </div>
  </a-drawer>
</template>

<script>
import { grouplist, cardlist, exportTemp, shownavigation } from '@/services/guide'
import { mapGetters, mapMutations } from 'vuex'
export default {
  name: 'GuideDrawer',
  filters: {
    // 去除文本中的br标签
    replaceBr(val) {
      return val && val.replaceAll('<br/>', '')
    }
  },
  data() {
    return {
      link: false,
      excelImg: require('@/assets/img/xlsx.png'),
      visible: false, // 是否展示
      isPackUp: false, // 是否是展开状态
      currentIndex: 0, // 当前选择的菜单
      currentCardCode: '', //
      menuList: [], // 菜单列表
      allCardList: {}, // 所有的卡片list
      downX: 0, // 鼠标位置记录
      moveX: 0 // 鼠标移动位置记录
    }
  },
  computed: {
    ...mapGetters('setting', ['menuData']),
    // 菜单渐变效果的判断依据
    diffValue() {
      return (index) => {
        const { currentIndex } = this
        const value = Math.abs(currentIndex - index)
        if (value > 2) {
          return 3
        } else {
          return value
        }
      }
    },
    // 根据currentIndex查询当前菜单
    currentMenu() {
      return this.menuList[this.currentIndex] || this.menuList[0] || { dependGroupName: [] }
    },
    // 根据currentMenu 获取当前卡片的list
    currentCardList() {
      const { allCardList, allMenuData } = this
      const { groupCode } = this.currentMenu
      if (groupCode && allCardList[groupCode]) {
        // 取当前卡片列表
        const allCard = allCardList[groupCode]
        // 遍历 添加禁用的属性
        const filterCard = allCard.map((item) => {
          const { routePath, dependCardCode } = item
          // 不需要跳转的卡片 则直接通过 不需要判断权限 和 依赖
          if (!routePath) {
            return {
              ...item,
              disabled: false
            }
          }
          const [routerName] = routePath?.split('_')
          // 判断路由表里是否有该权限
          const isPermission = allMenuData.includes(routerName)
          if (isPermission) {
            // 判断依赖项是否完成
            const { disabled, message: messageText } = this.dependRelation(dependCardCode)
            return {
              ...item,
              disabled,
              messageText
            }
          } else {
            return {
              ...item,
              disabled: true,
              messageText: '暂无权限'
            }
          }
        })
        return filterCard
      } else {
        return []
      }
    },
    // 获取权限表的funccode字段 方便后期判断
    allMenuData() {
      const { menuData } = this
      const allMenuText = []
      const getName = (list) => {
        list.forEach(({ children, name }) => {
          if (children && children.length > 0) {
            getName(children)
          }
          allMenuText.push(name)
        })
      }
      getName(menuData)
      return allMenuText
    },
    // 防止拖动和点击冲突 设置一个值 来便于区分 点击和拖拽的动作
    // 鼠标 起始位置差 小于5 则判定为点击 否则 判定为滑动
    moveDistance() {
      const { downX, moveX } = this
      return Math.abs(downX - moveX) > 5
    },
    // 当前项的关联关系描述
    relationTip() {
      return ({ dependGroupName }) => {
        const dependGroupNameText = dependGroupName.join('、')
        return `配置前请先确认己完成${dependGroupNameText}环节`
      }
    }
  },
  watch: {
    currentIndex: {
      handler(val) {
        this.link = val === 0
      },
      immediate: true
    }
  },
  async mounted() {
    await this.shownavigation()
  },
  methods: {
    ...mapMutations('setting', ['setGlobalMenu_jump']),
    // 组件展示的方法
    async open() {
      await this.grouplist()
      await this.getCardlist()
      this.visible = true
      this.isPackUp = true
      this.setIndex()
      this.$nextTick(() => {
        this.scrollInit()
      })
    },
    // 打开收起控制
    async handelPackUp() {
      this.isPackUp = !this.isPackUp
      if (this.isPackUp) {
        await this.getCardlist()
        this.setIndex()
      }
    },
    // 控制菜单切换
    menuChange(index) {
      this.link = index === 0
      this.currentIndex = index
      this.resetScroll()
    },
    // 获取引导的菜单项
    async grouplist() {
      const { data } = await grouplist()
      const { allMenuData } = this
      // 根据当前的路由权限来获取 存在哪些引导项
      this.menuList = data.filter(({ funcCode }) => {
        if (!funcCode) {
          return true
        }
        return allMenuData.includes(funcCode)
      })
    },
    // 获取所有的卡片
    async getCardlist() {
      const { data } = await cardlist()
      this.allCardList = data
    },
    // 点击前往业务模块
    async goPage(item) {
      // 判断当前是点击还是拖动
      if (this.moveDistance) {
        return
      }
      // 判断 是否禁用（无权限 或者 不满足前置条件）并给出提示
      if (item.disabled) {
        this.$message.warning(item.messageText)
        return
      }
      // footerLink判断是否是导出
      if (item.footerLink) {
        await exportTemp(item.footerLink)
        return
      }
      // routePath包含了 用_下划线分开的 路由的name属性 和 params的参数 跳转时拆分开 跳转且？携带参数
      const routePathList = item.routePath?.split('_') || []
      const params = {}
      if (routePathList.length > 1) {
        params.globalMenu_jump = routePathList[1]
        this.setGlobalMenu_jump(routePathList[1])
      }
      this.$router.push({
        name: routePathList[0],
        params
      })
      // 缓存当前的cardCode 用来给卡片赋予active状态
      this.currentCardCode = item.cardCode
      // 每次点击卡片跳转以后 需要关闭引导
      this.isPackUp = false
    },
    // 获取是否要弹出
    async shownavigation() {
      const { data } = await shownavigation()
      if (!data) {
        this.open()
      }
    },
    // 判断依赖关系
    dependRelation(dependCardCode) {
      const { allCardList } = this
      try {
        // 遍历给出的依赖数组 从第一个开始判断 当依赖不满足时 返回disabled 和 tipMessage提示信息
        dependCardCode.forEach(({ dependGroupCode, tipMessage, dependCardCode }) => {
          // 根据当前的依赖项 去卡片列表获取到该卡片（dependGroupCode是卡片列表所在的对象的key）
          const { checkStatus = true } = allCardList[dependGroupCode].find((item) => {
            return dependCardCode === item.cardCode
          }) || {}
          // 根据目标卡片的checkStatus值来判断返回什么
          if (!checkStatus) {
            const error = {
              disabled: true,
              message: tipMessage
            }
            throw error
          }
        })
      } catch (error) {
        return error
      }
      // 上面循环通过了 说依赖项 checkStatus 都为true 则返回 disabled: false
      return {
        disabled: false
      }
    },
    // 根据路由判断当前应该在哪个菜单下
    setIndex() {
      const { name, fullPath } = this.$route || {}
      const [, firstFuncode] = fullPath.split('/')
      console.log('funCode', this.$route)
      // const funCode = ''
      // 若当前路由在某个菜单下 且不属于卡片 则调整到该大项下
      let lastIndex = -2 // 默认值给-2
      const index = this.menuList.findIndex(({ groupCode, funCode }, index) => {
        const cardList = this.allCardList[groupCode] || []
        const cardIndex = cardList.findIndex(({ routePath }) => {
          if (routePath) {
            const [routerCode] = routePath.split('_')
            return name === routerCode
          }
          return false
        })
        console.log('funcCode - menuList', funCode)
        console.log('funcCode - route', firstFuncode)
        if (lastIndex === -2 && firstFuncode !== '' && (firstFuncode === funCode)) {
          lastIndex = index
        }
        return cardIndex > -1
      })
      // 此处判断的事设置组织负责人时候 返回 固定设置为4 即 搭建组织体系
      if (index === 3 && this.currentCardCode === 'card_duty_org_staff') {
        this.currentIndex = 4
        return
      }
      // 如果没有找到卡片符合的 但是找到了funcCode符合的 则使用funcCode找到的项
      if (index === -1 && lastIndex !== -2) {
        this.currentIndex = lastIndex
        return
      }
      // 如果funcCode也没有匹配关系 卡片也没有匹配关系 则选中第一项
      if (index === -1 && lastIndex === -2) {
        this.currentIndex = 0
        return
      }
      this.currentIndex = index
    },
    // 卡片列表左右超出 鼠标控制左右滑动
    scrollInit() {
      // 获取要绑定事件的元素
      const cardList = document.getElementById('cardList')
      var flag // 鼠标按下
      var downX // 鼠标点击的x下标
      var scrollLeft // 当前元素滚动条的偏移量
      const that = this
      cardList.addEventListener('mousedown', function(event) {
        flag = true
        downX = event.clientX // 获取到点击的x下标
        that.$set(that, 'downX', event.clientX)
        scrollLeft = this.scrollLeft // 获取当前元素滚动条的偏移量
      })
      cardList.addEventListener('mousemove', function(event) {
        if (flag) { // 判断是否是鼠标按下滚动元素区域
          var moveX = event.clientX // 获取移动的x轴
          this.moveX = moveX
          var scrollX = moveX - downX // 当前移动的x轴下标减去刚点击下去的x轴下标得到鼠标滑动距离
          this.scrollLeft = scrollLeft - scrollX // 鼠标按下的滚动条偏移量减去当前鼠标的滑动距离
        }
      })
      // 鼠标抬起停止拖动
      cardList.addEventListener('mouseup', function(event) {
        that.$set(that, 'moveX', event.clientX)
        flag = false
      })
      // 鼠标离开元素停止拖动
      cardList.addEventListener('mouseleave', function(event) {
        flag = false
      })
    },
    // 重置滚动条的位置
    resetScroll() {
      // 横向滚动条置为0
      this.$nextTick(() => {
        document.getElementById('cardList').scrollLeft = 0
      })
    },
    // 动画实现
    beforeEnter(dom) {
      dom.classList.add('fade-list-enter-active')
    },
    enter(dom, done) {
      const delay = dom.dataset.delay
      setTimeout(() => {
        dom.classList.add('fade-list-enter-to')
        const transitionend = window.ontransitionend ? 'transitionend' : 'webkitTransitionEnd'
        dom.addEventListener(transitionend, function onEnd() {
          dom.removeEventListener(transitionend, onEnd)
          done() // 调用done() 告诉vue动画已完成，以触发 afterEnter 钩子
        })
      }, delay)
    },
    afterEnter(dom) {
      dom.classList.remove('fade-list-enter-to')
      dom.classList.remove('fade-list-enter-active')
    }
  }
}

</script>

<style lang="less" scoped>
ul, li {
  margin: 0;
  padding: 0;
}
.guide-drawer {
  .hearder-view {
    background: url('~@/assets/img/topBtn.png') no-repeat;
    background-size: 100% 100%;
    height: 39px;
    width: 178px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    top: -39px;
    z-index: 999;
    padding-top: 8px;
    font-size: 12px;
    color: @sc-grey-60;
    .down-icon {
      height: 14px;
      width: 14px;
      transition: transform .3s;
      transform: rotate(180deg);
      &.up-icon {
        transform: rotate(0);
      }
    }
  }
  .guide-content {
    box-shadow: 0px -2px 8px rgba(0,0,0,.1);
    background: #f7f8fa;
    border-radius: 10px 10px 0 0;
    &.guide-content-full {
      display: flex;
      height: 280px;
      position: relative;
      overflow: hidden;
      transition: height .3s;
      ul.guide-menu-view {
        padding: 20px;
        position: relative;
        transition: transform .2s linear;
        li {
          display: flex;
          align-items: center;
          cursor: pointer;
          padding-bottom: 10px;
          position: relative;
          z-index: 2;
          &:last-child {
            .dot-view {
              &::after {
                display: none;
              }
            }
          }
          &.menu-item-1 {
            color: @sc-grey-100;
          }
          &.menu-item-2 {
            color: @sc-grey-60;
          }
          &.menu-item-3 {
            color: @sc-grey-40;
          }
          .name-view {
            display: flex;
            align-items: center;
            height: 32px;
            width: 114px;
            flex-shrink: 0;
            justify-content: space-around;
            &.current {
              color: #fff;
            }
          }
          .dot-view {
            display: block;
            height: 10px;
            width: 10px;
            background: #E9EFFE;
            border-radius: 50%;
            margin-right: 20px;
            position: relative;
            &::after {
              content: "";
              display: block;
              height: 39px;
              border-right: 1px solid rgba(81,121,251,0.1000);
              position: absolute;
              bottom: -39px;
              left: 50%;
              transform: translateX(-50%);
            }
          }
        }
      }
      .guide-step {
        flex: 1;
        padding: 20px;
        width: calc(100vw - 280px);
        .step-title {
          position: relative;
          .current-menu {
            color: @sc-grey-100;
            font-size: 16px;
            line-height: 24px;
            padding-bottom: 6px;
            display: flex;
            align-items: center;
            .shortName {
              display: flex;
              align-items: center;
            }
            img {
              height: 16px;
              width: 16px;
              margin-right: 5px;
            }
          }
          .description {
            color: @sc-grey-60;
            padding-bottom: 20px;
          }
          .clase-view {
            height: 16px;
            width: 16px;
            color: @sc-grey-40;
            position: absolute;
            right: -20px;
            top: -20px;
            cursor: pointer;
            height: 26px;
            width: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        .card-list {
          display: flex;
          width: 100%;
          overflow-x: auto;
          overflow-y: hidden;
          .card-item {
            height: 170px;
            width: 266px;
            margin-right: 20px;
            background: #fff;
            border-radius: 8px;
            padding: 12px;
            padding-right: 18px;
            flex-shrink: 0;
            cursor: pointer;
            &:hover {
              box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
              .card-name {
                .card-title {
                  color: @sc-primary-100;
                }
              }
            }
            &:last-child {
              margin: 0;
            }
            .card-name {
              display: flex;
              align-items: center;
              justify-content: space-between;
              .card-name-text {
                display: flex;
                align-items: center;
              }
              .sort-warnning {
                font-size: 12px;
                height: 20px;
                width: 20px;
                border-radius: 50%;
                background: #E34D59;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-shrink: 0;
              }
              .sort-success {
                color: @sc-success-100;
              }
              .sort-num {
                height: 16px;
                width: 16px;
                border-radius: 50%;
                border: 1px solid @sc-primary-100;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 12px;
                color: @sc-primary-100;
                margin-right: 6px;
              }
              .card-title {
                font-size: 16px;
                color: @sc-grey-100;
              }
            }
            .sub-title {
              color: @sc-grey-60;
              width: 100%;
              white-space:nowrap;
              text-overflow:ellipsis;
              -o-text-overflow:ellipsis;
              overflow:hidden;
            }
            .step-list {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              padding-top: 10px;
              .step-item,
              .step-item2 {
              padding: 5px 8px;
              background: #e8e9eb;
              border-radius: 4px;
              display: flex;
              margin-right: 6px;
              margin-bottom: 10px;
              height: 32px;
              min-width: 115px;
              justify-content: space-between;
              .stepImg {
                width: 16px;
                height: 20px;
                margin-top: -2px;
                margin-right: 8px;
                img {
                  width: 100%;
                  height: 100%;
                }
              }
                &:nth-child(2n) {
                  margin: 0;
                }
                .step-name {
                  font-size: 14px;
                  line-height: 22px;
                  margin-right: 20px;
                }
                .check-value {
                  color: @sc-primary-100;
                  &.check-status{
                    color: #E34D59;
                  }
                }
              }
              .step-item2 {
                width: unset;
                display: flex;
                justify-content: start;
                border: 1px solid @sc-primary-100;
                color: @sc-primary-100;
                background: #fff;
              }
            }
          }
          .card-item-disabled {
            filter: grayscale(100%);
            cursor: no-drop;
          }
          .card-item-active {
            .card-title {
              color: @sc-primary-100 !important;
            }
          }
        }
      }
      &.unfold {
        height: 0px;
      }
    }
    .active-block {
      position: absolute;
      left: 0;
      top: 99px;
      display: flex;
      align-items: center;
      padding-bottom: 10px;
      padding-left: 20px;
      &::after {
        content: "";
        display: block;
        height: 24px;
        width: 23px;
        background: #507ff7;
        border-radius: 2px;
        transform: rotate(136deg);
        position: absolute;
        right: -11px;
        top: 4px;
      }
      .dot {
        display: block;
        height: 12px;
        width: 12px;
        background: @sc-primary-100;
        border-radius: 50%;
        margin-right: 20px;
        position: relative;
        z-index: 3;
      }
      .arrows {
        background: @sc-primary-100;
        height: 32px;
        width: 108px;
        border-radius: 4px;
      }
    }
    .down-active-block {
      top: 43px;
    }
  }
}
.iconpark-icon {
  width: 16px;
  height: 16px;
}

// 重置drawer样式
.ant-drawer {
  z-index: 2021 !important;
}
/deep/ .ant-drawer-header {
  padding: 0;
  border: none;
}
/deep/ .ant-drawer-body {
  padding: 0;
}
/deep/ .ant-drawer-content {
  background: none;
}
/deep/ .ant-drawer-content-wrapper {
  height: auto !important;
  box-shadow: none !important;
}
/deep/ .ant-drawer-wrapper-body {
  overflow: inherit;
}
/deep/ .ant-drawer-content {
  overflow: inherit;
}
.ant-tooltip-inner {
  background: #fff;
}
</style>
<style lang="less">
.sub-title-tooltip {
  z-index: 2023;
  .ant-tooltip-inner {
    padding: 10px 12px;
    background: #fff;
    color: @sc-grey-100;
  }
  .ant-tooltip-arrow::before {
    background-color: #fff
  }
}
.relation-tooltip {
  z-index: 2023;
}
.fade-list-enter-active {
  opacity: 0;
  transform: translateY(100%);
  transition: all 0.3s;
}
.fade-list-enter-to {
    opacity: 1;
    transform: translateY(0);
}
</style>
