<template>
  <div v-if="hasSubMenu" class="subMenu">
    <!-- && !getshowSet -->
    <div class="header">
      <div class="title font16">{{ getSubMenuTile }}</div>
      <!-- <div class="setting-box" @click="showSetting">
        <svg class="iconpark-icon" :style="{ width: '16px', height: '16px' }">
          <use class="icon-use" href="#setting-one" />
        </svg>
      </div> -->
    </div>
    <!-- 普通的二级菜单 -->
    <template v-if="subMenuType === 'default'">
      <ul :class="hasSubMenu ? 'subMenuListPadding' : 'subMenuListNone'">
        <template v-for="(key, index) in getSubMenuData">
          <li
            v-if="showMenu(key.fullPath)"
            :key="`subMenu${index}`"
            class="subMenuItem"
            :class="{ 'active': key.fullPath === $route.path, 'isSetting': key.meta.subName === '设置' }"
            @click="changePath(key.fullPath)"
          >
            <svg :class="iconList.includes(key.meta.icon) ? 'iconpark-icon icon-new' : 'iconpark-icon icon-old'">
              <use class="icon-use" :href="`${key.meta.icon}`" />
            </svg>
            {{ key.meta.subName }}
          </li>
        </template>
      </ul>
    </template>
    <!-- 分级的二级菜单 -->
    <template v-else>
      <div class="group">
        <div v-for="(key, index) in getSubMenuData" :key="`group${index}`" class="group-item">
          <div v-if="key.meta.subName !== '设置'" class="subTitle ">{{ key.meta.subName }}</div>
          <div v-else class="isSetting subMenuItem subSetting" :class="{ 'active': key.fullPath === $route.path }" @click="changePath(key.fullPath)">
            <svg class="iconpark-icon" :style="{ width: '16px', height: '16px', marginRight: '8px' }">
              <use class="icon-use" :href="`${key.meta.icon}`" />
            </svg>
            {{ key.meta.subName }}
          </div>
          <div class="content">
            <template v-for="(item, contentItemIndex) in key.children">
              <div
                :key="`contentItem${contentItemIndex}`"
                class="contentItem subMenuItem"
                :class="{ 'active': item.fullPath === $route.path }"
                @click="changePath(item.fullPath)"
              >
                <svg class="iconpark-icon" :style="{ width: '16px', height: '16px', marginRight: '8px' }">
                  <use class="icon-use" :href="`${item.meta.icon}`" />
                </svg>
                {{ item.meta.subName }}
              </div>
            </template>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
  name: 'SubMenu',
  data() {
    return {
      showSet: false,
      iconList: ['#people-upload-5m15g2li', '#right-user-5m15gc1n', '#reduce-user-5m15hgb0'],
      hideMenuList: [
        {
          name: '用户详情',
          path: '/staffIndex/userDetail'
        },
        {
          name: '档案详情',
          path: '/socialSecurity/securityArchivesDetail'
        },
        {
          name: '合同详情',
          path: '/contract/contractDetail'
        }
      ]
    }
  },
  computed: {
    hasSubMenu() {
      return this.getSubMenuData.length > 0
    },
    // default 普通二级菜单  level  拥有分组效果的二级菜单
    subMenuType() {
      let type = 'default'
      const menuData = this.getSubMenuData
      for (let i = 0; i < menuData.length; i++) {
        if (menuData[i].children && menuData[i].children.length > 0) {
          type = 'level'
          break
        }
      }
      return type
    },
    ...mapGetters('setting', ['getSubMenuData', 'getSubMenuTile', 'getshowSet'])
  },
  watch: {
    getSubMenuData() {
      if (this.getSubMenuData.length === 0) return
      let isHref = true
      this.getSubMenuData.forEach((item) => {
        if (item.children && item.children.length > 0) {
          item.children.forEach(key => {
            if (key.fullPath === this.$route.path) {
              isHref = false
            }
          })
        } else if (item.fullPath === this.$route.path) {
          isHref = false
        }
      })
      if (this.getSubMenuData[0].fullPath !== this.$route.path && isHref) {
        if (this.getSubMenuData[0].children && this.getSubMenuData[0].children.length > 0) {
          this.$router.push(this.getSubMenuData[0].children[0].fullPath)
        } else {
          this.$router.push(this.getSubMenuData[0].fullPath)
        }
      }
    }
  },
  mounted() {
    const accountInfo = JSON.parse(localStorage.getItem(process.env.VUE_APP_ACCOUNT_KEY))
    const { isAuthority, isCreator } = accountInfo
    // isAuthority 是否可操作权限设置（0否1是）
    if (isCreator !== '1' && isAuthority === '0') {
      this.hideMenuList.push({
        name: '权限设置',
        path: '/globalSetting/globalSettingAuthor'
      })
    }
  },
  methods: {
    ...mapMutations('setting', ['setShowSet']),
    showMenu(fullPath) {
      for (let i = 0; i < this.hideMenuList.length; i++) {
        if (this.hideMenuList[i].path === fullPath) {
          return false
        }
      }
      return true
    },
    changePath(path) {
      if (path === this.$route.path) {
        return
      }
      this.$router.push(path)
    },
    showSetting() {
      this.setShowSet()
      this.$router.push('/wagesIndex/wageSetting')
    }
  }
}
</script>

<style lang="less" scoped>
.subMenu {
  background-color: #F7F8FA;
  width: 144PX;
  height: 100%;
  overflow: auto;
  position: relative;
  line-height: 22px;
  font-size: 14px;
  color: @sc-grey-60;
  &::-webkit-scrollbar {
    display: none;
  }
  .header {
    cursor:pointer;
    .flex-align-center();
    justify-content: space-between;
    .setting-box {
      .flex-align-center();
      padding: 0px 10px;
    }
  }
  .title {
      margin: 20px;
      margin-left: 16px;
      color: @sc-grey-100;
      font-size: 16PX;
    }
  .subMenuListNone {
    padding: 0;
  }
  .subMenuListPadding {
    text-align: center;
    margin: 10px 5px;
  }
  .subMenuItem {
    list-style: none;
    cursor: pointer;
    margin-bottom: 10PX;
    padding: 8PX 0 8PX 11PX;
    text-align: left;
    display: flex;
    align-items: center;
    border-radius: 4px;
    color: @sc-grey-100;
    &:hover{
      background: @sc-greyBg-10;
      border-radius: 4PX;
      cursor: pointer;
    }
    .iconpark-icon {
      color: @sc-grey-100;
    }
  }
  .active {
    border-radius: 4px;
    background-color: @sc-primary-10;
    color: @sc-primary-100!important;
    .iconpark-icon{
      .icon-use{
        color: @sc-primary-100;
      }
    }
  }
  .icon-old {
    width: 16px;
    height: 16px;
    margin-right: 10px;
    color: @sc-grey-100;
  }
  .icon-new {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
}
.isSetting {
  background: none !important;
  border: none !important;
  color: @sc-grey-80 !important;
}
.subSetting {
  margin-left: 5px;
}
.group{
  .subTitle{
    margin: 0 0 2PX 16PX;
    font-size: 12PX;
    color: @sc-grey-40;
  }
  .group-item{
    margin-bottom: 20PX;
  }
  .contentItem{
    margin:10PX 5px;
    line-height: 22px;
    display: flex;
    padding:8px 11PX ;
    align-items: center;
    border-radius: 4px;
    font-size:14PX;
    color: @sc-grey-100;
    &:hover{
      background: @sc-greyBg-10;
      border-radius: 4PX;
      cursor: pointer;
    }
    &:focus{
     background: rgba(81, 121, 251, 0.1);
      border-radius: 4PX;
      color: #5179FB;
    }
    .menuIcon{
      margin: 0 2PX 0 0;
    }
  }
}
</style>
