var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c("top-header", {
        on: {
          showGuide: function ($event) {
            return _vm.$refs.guideDrawer.open()
          },
        },
      }),
      _c(
        "a-layout",
        { class: ["admin-layout", "beauty-scroll"] },
        [
          _vm.layout === "side" || _vm.layout === "mix"
            ? _c("side-menu", {
                staticClass: "sideBox",
                class: [_vm.fixedSideBar ? "fixed-side" : ""],
                attrs: {
                  theme: _vm.theme.mode,
                  "menu-data": _vm.sideMenuData,
                  collapsed: _vm.collapsed,
                  collapsible: true,
                },
              })
            : _vm._e(),
          _c(
            "a-layout",
            { staticClass: "admin-layout-main beauty-scroll contentBox" },
            [
              _c("admin-header", {
                class: [
                  {
                    "fixed-tabs": _vm.fixedTabs,
                    "fixed-header": _vm.fixedHeader,
                    "multi-page": _vm.multiPage,
                  },
                ],
                staticStyle: { display: "none" },
                style: _vm.headerStyle,
                attrs: {
                  "menu-data": _vm.headMenuData,
                  collapsed: _vm.collapsed,
                },
                on: { toggleCollapse: _vm.toggleCollapse },
              }),
              _c("a-layout-header", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.fixedHeader,
                    expression: "fixedHeader",
                  },
                ],
                class: [
                  "virtual-header",
                  {
                    "fixed-tabs": _vm.fixedTabs,
                    "fixed-header": _vm.fixedHeader,
                    "multi-page": _vm.multiPage,
                  },
                ],
              }),
              _c(
                "a-layout-content",
                {
                  staticClass: "admin-layout-content",
                  style: "min-height: " + _vm.minHeight + "px;",
                },
                [
                  _c(
                    "div",
                    { staticStyle: { position: "relative", height: "100%" } },
                    [_vm._t("default")],
                    2
                  ),
                  _c("guideDrawer", { ref: "guideDrawer" }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }