var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "header" }, [
    _c("div", { staticClass: "header-card", attrs: { size: "small" } }, [
      _c(
        "div",
        { staticClass: "header-container flex-just-between flex-align-center" },
        [
          _c("div", { staticClass: "header-name" }, [
            _c(
              "span",
              { staticClass: "company-name font14 flex-align-center" },
              [
                _vm._v(
                  " " + _vm._s(_vm.tenant ? _vm.tenant.tenantName : "") + " "
                ),
              ]
            ),
          ]),
          _c(
            "div",
            { staticClass: "header-search flex-align-center" },
            [
              _c(
                "SearchPopover",
                {
                  ref: "searchPopover",
                  attrs: { "search-value": _vm.searchValue },
                  on: {
                    "update:searchValue": function ($event) {
                      _vm.searchValue = $event
                    },
                    "update:search-value": function ($event) {
                      _vm.searchValue = $event
                    },
                  },
                },
                [
                  _c("template", { slot: "search" }, [
                    _c(
                      "span",
                      {
                        staticClass: "search-box",
                        on: { click: _vm.openSearch },
                      },
                      [
                        _c(
                          "a-input",
                          {
                            attrs: {
                              size: "small",
                              placeholder:
                                _vm.$refs.searchPopover &&
                                _vm.$refs.searchPopover.intPlaceholder,
                            },
                            on: { keydown: _vm.confirmSearch },
                            model: {
                              value: _vm.searchValue,
                              callback: function ($$v) {
                                _vm.searchValue = $$v
                              },
                              expression: "searchValue",
                            },
                          },
                          [
                            _c("template", { slot: "suffix" }, [
                              _c(
                                "svg",
                                {
                                  staticClass: "iconpark-icon suffix-search",
                                  on: {
                                    click: function ($event) {
                                      return _vm.$refs.searchPopover.search()
                                    },
                                  },
                                },
                                [_c("use", { attrs: { href: "#search" } })]
                              ),
                            ]),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
              _c(
                "HistoryPopover",
                [
                  _c(
                    "template",
                    { slot: "icon" },
                    [
                      _c(
                        "a-tooltip",
                        {
                          attrs: { slot: "icon", placement: "bottom" },
                          slot: "icon",
                        },
                        [
                          _c("template", { slot: "title" }, [
                            _vm._v(" 近期访问 "),
                          ]),
                          _c("span", { staticClass: "icon-box ml-18" }, [
                            _c(
                              "svg",
                              {
                                staticClass:
                                  "iconpark-icon icon-bell flex-center",
                              },
                              [_c("use", { attrs: { href: "#zu1233" } })]
                            ),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
              _c(
                "BellPopover",
                { ref: "BellPopover" },
                [
                  _c(
                    "a-tooltip",
                    {
                      attrs: { slot: "icon", placement: "bottom" },
                      slot: "icon",
                    },
                    [
                      _c("template", { slot: "title" }, [_vm._v(" 站内信 ")]),
                      [
                        _c(
                          "span",
                          {
                            staticClass: "icon-box",
                            on: {
                              click: function ($event) {
                                return _vm.$refs.BellPopover.show()
                              },
                            },
                          },
                          [
                            _c(
                              "svg",
                              {
                                staticClass:
                                  "iconpark-icon icon-bell flex-center",
                              },
                              [_c("use", { attrs: { href: "#zu1234" } })]
                            ),
                          ]
                        ),
                      ],
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "a-popover",
                {
                  attrs: {
                    "overlay-class-name": "function-popover",
                    placement: "topRight",
                    trigger: "click",
                    "get-popup-container": function (triggerNode) {
                      return triggerNode.parentNode
                    },
                  },
                  model: {
                    value: _vm.feedVisible,
                    callback: function ($$v) {
                      _vm.feedVisible = $$v
                    },
                    expression: "feedVisible",
                  },
                },
                [
                  _c("template", { slot: "content" }, [
                    _c("div", { staticClass: "function-list dont-copy" }, [
                      _c(
                        "div",
                        {
                          staticClass: "function-item",
                          on: { click: _vm.addFeedBack },
                        },
                        [
                          _c("svg", { staticClass: "iconpark-icon cutton" }, [
                            _c("use", { attrs: { href: "#tips-one" } }),
                          ]),
                          _vm._v(" 问题反馈 "),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "function-item",
                          on: {
                            click: function ($event) {
                              _vm.$emit("showGuide")
                              _vm.feedVisible = false
                            },
                          },
                        },
                        [
                          _c("svg", { staticClass: "iconpark-icon cutton" }, [
                            _c("use", { attrs: { href: "#tips-6bi0hmob" } }),
                          ]),
                          _vm._v(" 配置导览 "),
                        ]
                      ),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "icon-box" },
                    [
                      _c(
                        "a-tooltip",
                        {
                          attrs: { slot: "icon", placement: "bottom" },
                          slot: "icon",
                        },
                        [
                          _c("template", { slot: "title" }, [
                            _vm._v(" 问题反馈 "),
                          ]),
                          _c("a-icon", {
                            staticClass: "icon icon-bell function flex-center",
                            attrs: { type: "question-circle", theme: "filled" },
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
              _c(
                "AvaterPopover",
                {
                  ref: "avaterPopover",
                  attrs: { "account-info": _vm.accountInfo },
                },
                [
                  _c("template", { slot: "avaterPopover" }, [
                    _c(
                      "div",
                      {
                        staticClass: "info-avatar",
                        on: { click: _vm.avatarUser },
                      },
                      [
                        _c(
                          "a-tooltip",
                          {
                            attrs: {
                              placement: "bottom",
                              "mouse-enter-delay": 0.2,
                              "overlay-class-name": "info-tooltip",
                              "get-popup-container": function (triggerNode) {
                                return triggerNode.parentNode
                              },
                            },
                          },
                          [
                            _c("template", { slot: "title" }, [
                              _vm._v(" 个人信息 "),
                            ]),
                            _c("CbAvater", {
                              attrs: {
                                "on-job-id": _vm.accountInfo.onJobId,
                                "avater-name": _vm.accountInfo.staffName
                                  ? _vm.accountInfo.staffName
                                  : _vm.accountInfo.userName
                                  ? _vm.accountInfo.userName
                                  : "",
                                size: "30",
                                "show-user-name": false,
                              },
                            }),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
              _c("FeedbackModal", { ref: "feedbackModal" }),
            ],
            1
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }