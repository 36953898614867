
import {
  DICTIONARY
} from '../api'
import {
  METHOD,
  request
} from '@/utils/request'

// 导航列表
export const grouplist = async(params) =>
  request(`${DICTIONARY}/config/navigation/grouplist`, METHOD.GET, params)

export const cardlist = async(params) =>
  request(`${DICTIONARY}/config/navigation/cardlist`, METHOD.GET, params)

export const exportTemp = async(url, params) =>
  request(url, METHOD.GET, params, {
    responseType: 'blob',
    headers: {
      isDownload: true
    }
  })

export const shownavigation = async(params) =>
  request(`${DICTIONARY}/config/navigation/shownavigation`, METHOD.GET, params)
