<template>
  <div class="content">
    <top-header @showGuide="$refs.guideDrawer.open()" />
    <a-layout :class="['admin-layout', 'beauty-scroll']">
      <side-menu
        v-if="layout === 'side' || layout === 'mix'"
        class="sideBox"
        :class="[fixedSideBar ? 'fixed-side' : '']"
        :theme="theme.mode"
        :menu-data="sideMenuData"
        :collapsed="collapsed"
        :collapsible="true"
      />
      <!-- <div v-if="fixedSideBar && !isMobile" :style="`min-width: ${sideMenuWidth};`" class="virtual-side"></div> -->
      <!-- <drawer v-if="!hideSetting" v-model="showSetting" placement="right">
      <div slot="handler" class="setting">
        <a-icon :type="showSetting ? 'close' : 'setting'" />
      </div>
      <setting />
    </drawer> -->
      <a-layout class="admin-layout-main beauty-scroll contentBox">
        <admin-header
          style="display:none"
          :class="[
            {
              'fixed-tabs': fixedTabs,
              'fixed-header': fixedHeader,
              'multi-page': multiPage
            }
          ]"
          :style="headerStyle"
          :menu-data="headMenuData"
          :collapsed="collapsed"
          @toggleCollapse="toggleCollapse"
        />
        <a-layout-header
          v-show="fixedHeader"
          :class="[
            'virtual-header',
            {
              'fixed-tabs': fixedTabs,
              'fixed-header': fixedHeader,
              'multi-page': multiPage
            }
          ]"
        />
        <a-layout-content class="admin-layout-content" :style="`min-height: ${minHeight}px;`">
          <div style="position: relative;height:100%;">
            <slot />
          </div>
          <!-- 引导页面 -->
          <guideDrawer ref="guideDrawer" />
        </a-layout-content>
      <!-- <a-layout-footer style="padding: 0px">
        <page-footer :link-list="footerLinks" :copyright="copyright" />
      </a-layout-footer> -->
      </a-layout>
    </a-layout>
  </div>
</template>

<script>
import AdminHeader from './header/AdminHeader'
import TopHeader from './TopHeader/index.vue'
// import PageFooter from './footer/PageFooter'
import SideMenu from '../components/menu/SideMenu'
// import Setting from '../components/setting/Setting'
import { mapState, mapMutations, mapGetters } from 'vuex'
import guideDrawer from './guideDrawer'
// const minHeight = window.innerHeight - 64 - 122
export default {
  name: 'AdminLayout',
  components: {
    SideMenu,
    AdminHeader,
    TopHeader,
    guideDrawer
  },
  data() {
    return {
      minHeight: window.innerHeight - 64 - 122,
      collapsed: false,
      showSetting: false,
      drawerOpen: false
    }
  },
  provide() {
    return {
      adminLayout: this
    }
  },
  computed: {
    ...mapState('setting', [
      'isMobile',
      'theme',
      'layout',
      'footerLinks',
      'copyright',
      'fixedHeader',
      'fixedSideBar',
      'fixedTabs',
      'hideSetting',
      'multiPage',
      'menuWidth'
    ]),
    ...mapGetters('setting', ['firstMenu', 'subMenu', 'menuData']),
    sideMenuWidth() {
      // return this.collapsed ? '80px' : '256px'
      // 去掉展开收缩功能，定宽写死130
      return this.menuWidth
    },
    headerStyle() {
      const width =
        this.fixedHeader && this.layout !== 'head' && !this.isMobile
          ? `calc(100% - ${this.sideMenuWidth})`
          : '100%'
      const position = this.fixedHeader ? 'fixed' : 'static'
      return `width: ${width}; position: ${position};`
    },
    headMenuData() {
      const { layout, menuData, firstMenu } = this
      return layout === 'mix' ? firstMenu : menuData
    },
    sideMenuData() {
      const { layout, menuData, subMenu } = this
      return layout === 'mix' ? subMenu : menuData
    }
  },
  watch: {
    layout() {
      this.setActivated(this.$route)
    },
    isMobile(val) {
      if (!val) {
        this.drawerOpen = false
      }
    }
  },
  methods: {
    ...mapMutations('setting', ['correctPageMinHeight', 'setActivatedFirst']),
    toggleCollapse() {
      this.collapsed = !this.collapsed
    },
    onMenuSelect() {
      this.toggleCollapse()
    }
    // setActivated(route) {
    //   if (this.layout === 'mix') {
    //     let matched = route.matched
    //     matched = matched.slice(0, matched.length - 1)
    //     const {firstMenu} = this
    //     for (let menu of firstMenu) {
    //       if (matched.findIndex(item => item.path === menu.fullPath) !== -1) {
    //         this.setActivatedFirst(menu.fullPath)
    //         break
    //       }
    //     }
    //   }
    // }
  }
  // created() {
  //   this.correctPageMinHeight(this.minHeight - 24)
  //   this.setActivated(this.$route)
  // },
  // beforeDestroy() {
  //   this.correctPageMinHeight(-this.minHeight + 24)
  // }
}
</script>

<style lang="less" scoped>
.content{
  height:100%;
}
.admin-layout {
  height: calc(100% - 48px)!important;
  .side-menu {
    &.fixed-side {
      position: fixed;
      height: 100vh;
      left: 0;
      top: 0;
    }
  }
  .sideBox {
    display: flex;
    // margin-right: 5px;
  }
  .virtual-side {
    transition: all 0.2s;
  }
  .virtual-header {
    transition: all 0.2s;
    opacity: 0;
    &.fixed-tabs.multi-page:not(.fixed-header) {
      height: 0;
    }
  }
  .contentBox {
    flex: 1;
  }
  .admin-layout-main {
    .admin-header {
      top: 0;
      right: 0;
      overflow: hidden;
      transition: all 0.2s;
      &.fixed-tabs.multi-page:not(.fixed-header) {
        height: 0;
      }
    }
  }
  .admin-layout-content {
    // padding: 24px 0 0;
    padding: 0;
    height: 100%;
    /*overflow-x: hidden;*/
    /*min-height: calc(100vh - 64px - 122px);*/
  }
  .setting {
    background-color: @primary-color;
    color: @base-bg-color;
    border-radius: 5px 0 0 5px;
    line-height: 40px;
    font-size: 22px;
    width: 40px;
    height: 40px;
    box-shadow: -2px 0 8px @shadow-color;
  }
}
</style>
