<template>
  <div>
    <a-layout-sider
      v-model="collapsed"
      :theme="sideTheme"
      class="default-style"
      :class="['side-menu', 'beauty-scroll', isMobile ? null : '']"
      :collapsible="collapsible"
      :trigger="null"
    >
      <!-- <div :class="['logo', theme]" /> -->
      <i-menu :theme="theme" :collapsed="collapsed" :options="menuData" class="menu" @select="onSelect" />
    </a-layout-sider>
    <subMenu />
  </div>
</template>

<script>
import IMenu from './menu.vue'
import subMenu from './subMenu.vue'
import { mapState, mapMutations } from 'vuex'
import mixins from '@/components/CustomTable/index.js'

export default {
  name: 'SideMenu',
  components: { IMenu, subMenu },
  mixins: [mixins],
  props: {
    collapsible: {
      type: Boolean,
      required: false,
      default: false
    },
    collapsed: {
      type: Boolean,
      required: false,
      default: false
    },
    menuData: {
      type: Array,
      required: true
    },
    theme: {
      type: String,
      required: false,
      default: 'dark'
    }
  },
  data() {
    return {
    }
  },
  computed: {
    sideTheme() {
      return this.theme === 'light' ? this.theme : 'dark'
    },
    ...mapState('setting', ['isMobile', 'systemName', 'menuWidth'])
  },
  watch: {
    $route() {
      this.setSubMenuTile(this.$route.matched[1].meta.subName || '')
    }
  },
  mounted() {
    this.setSubMenuTile(this.$route.matched[1].meta.subName || '')
  },
  methods: {
    onSelect(obj) {
      this.$emit('menuSelect', obj)
    },
    ...mapMutations('setting', ['setSubMenuTile'])
  }
}
</script>

<style lang="less" scoped>
@import "index";
.default-style{
  box-sizing: border-box;
  background-image: linear-gradient(to bottom , #2D457A, #1C2D51);
  width: 50PX!important;
  min-width: 62PX!important;
  max-width: 62PX!important;
  margin-right: 2PX;
  &::-webkit-scrollbar {
    display: none;
  }
}
.menu{
  margin-top: 14px;
}
</style>
