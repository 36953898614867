var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasSubMenu
    ? _c(
        "div",
        { staticClass: "subMenu" },
        [
          _c("div", { staticClass: "header" }, [
            _c("div", { staticClass: "title font16" }, [
              _vm._v(_vm._s(_vm.getSubMenuTile)),
            ]),
          ]),
          _vm.subMenuType === "default"
            ? [
                _c(
                  "ul",
                  {
                    class: _vm.hasSubMenu
                      ? "subMenuListPadding"
                      : "subMenuListNone",
                  },
                  [
                    _vm._l(_vm.getSubMenuData, function (key, index) {
                      return [
                        _vm.showMenu(key.fullPath)
                          ? _c(
                              "li",
                              {
                                key: "subMenu" + index,
                                staticClass: "subMenuItem",
                                class: {
                                  active: key.fullPath === _vm.$route.path,
                                  isSetting: key.meta.subName === "设置",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.changePath(key.fullPath)
                                  },
                                },
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    class: _vm.iconList.includes(key.meta.icon)
                                      ? "iconpark-icon icon-new"
                                      : "iconpark-icon icon-old",
                                  },
                                  [
                                    _c("use", {
                                      staticClass: "icon-use",
                                      attrs: { href: "" + key.meta.icon },
                                    }),
                                  ]
                                ),
                                _vm._v(" " + _vm._s(key.meta.subName) + " "),
                              ]
                            )
                          : _vm._e(),
                      ]
                    }),
                  ],
                  2
                ),
              ]
            : [
                _c(
                  "div",
                  { staticClass: "group" },
                  _vm._l(_vm.getSubMenuData, function (key, index) {
                    return _c(
                      "div",
                      { key: "group" + index, staticClass: "group-item" },
                      [
                        key.meta.subName !== "设置"
                          ? _c("div", { staticClass: "subTitle " }, [
                              _vm._v(_vm._s(key.meta.subName)),
                            ])
                          : _c(
                              "div",
                              {
                                staticClass: "isSetting subMenuItem subSetting",
                                class: {
                                  active: key.fullPath === _vm.$route.path,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.changePath(key.fullPath)
                                  },
                                },
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    staticClass: "iconpark-icon",
                                    style: {
                                      width: "16px",
                                      height: "16px",
                                      marginRight: "8px",
                                    },
                                  },
                                  [
                                    _c("use", {
                                      staticClass: "icon-use",
                                      attrs: { href: "" + key.meta.icon },
                                    }),
                                  ]
                                ),
                                _vm._v(" " + _vm._s(key.meta.subName) + " "),
                              ]
                            ),
                        _c(
                          "div",
                          { staticClass: "content" },
                          [
                            _vm._l(
                              key.children,
                              function (item, contentItemIndex) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      key: "contentItem" + contentItemIndex,
                                      staticClass: "contentItem subMenuItem",
                                      class: {
                                        active:
                                          item.fullPath === _vm.$route.path,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.changePath(item.fullPath)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "svg",
                                        {
                                          staticClass: "iconpark-icon",
                                          style: {
                                            width: "16px",
                                            height: "16px",
                                            marginRight: "8px",
                                          },
                                        },
                                        [
                                          _c("use", {
                                            staticClass: "icon-use",
                                            attrs: {
                                              href: "" + item.meta.icon,
                                            },
                                          }),
                                        ]
                                      ),
                                      _vm._v(
                                        " " + _vm._s(item.meta.subName) + " "
                                      ),
                                    ]
                                  ),
                                ]
                              }
                            ),
                          ],
                          2
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ],
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }