var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: ["tabs-head", _vm.layout, _vm.pageWidth] }, [
    _vm.affixed ? _c("div", { staticClass: "virtual-tabs" }) : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }