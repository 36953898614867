var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        visible: _vm.visible,
        closable: false,
        "mask-closable": false,
        mask: _vm.isPackUp,
        placement: "bottom",
      },
      on: {
        close: function ($event) {
          _vm.visible = false
        },
      },
    },
    [
      _c("div", { staticClass: "guide-drawer dont-copy" }, [
        _vm.visible
          ? _c(
              "div",
              { staticClass: "hearder-view", on: { click: _vm.handelPackUp } },
              [
                _c("div", [_vm._v(" 配置导览 ")]),
                _c("a-icon", {
                  staticClass: "down-icon",
                  class: { "up-icon": _vm.isPackUp },
                  attrs: { type: "down" },
                }),
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          {
            staticClass: "guide-content guide-content-full",
            class: { unfold: !_vm.isPackUp },
          },
          [
            _c("div", { staticClass: "active-block" }, [
              _c("div", { staticClass: "dot" }),
              _c("div", { staticClass: "arrows" }),
            ]),
            _c("div", [
              _c(
                "ul",
                {
                  staticClass: "guide-menu-view dont-copy",
                  style: {
                    transform:
                      "translate(0, calc(78px - " +
                      42 * _vm.currentIndex +
                      "px))",
                  },
                },
                _vm._l(_vm.menuList, function (item, index) {
                  return _c(
                    "li",
                    {
                      key: item.cardCode,
                      class: "menu-item-" + _vm.diffValue(index),
                      on: {
                        click: function ($event) {
                          return _vm.menuChange(index)
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "dot-view" }),
                      _c(
                        "div",
                        {
                          staticClass: "name-view",
                          class: { current: _vm.currentIndex === index },
                        },
                        [_vm._v(" " + _vm._s(item.name) + " ")]
                      ),
                    ]
                  )
                }),
                0
              ),
            ]),
            _c(
              "div",
              { staticClass: "guide-step" },
              [
                _c("div", { staticClass: "step-title" }, [
                  _c("div", [
                    _vm.currentMenu.dependGroupName &&
                    _vm.currentMenu.dependGroupName.length
                      ? _c(
                          "div",
                          { staticClass: "current-menu" },
                          [
                            _c(
                              "a-tooltip",
                              {
                                attrs: {
                                  title: _vm.relationTip(_vm.currentMenu),
                                  trigger: "hover",
                                  placement: "topLeft",
                                  "overlay-class-name": "relation-tooltip",
                                },
                              },
                              [
                                _c("span", { staticClass: "shortName" }, [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/img/global/info-circle-filled.png"),
                                      alt: "",
                                    },
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.currentMenu.shortName) +
                                      " "
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        )
                      : _c("div", { staticClass: "current-menu" }, [
                          _vm._v(" " + _vm._s(_vm.currentMenu.shortName) + " "),
                        ]),
                    _c("div", { staticClass: "description" }, [
                      _vm._v(" " + _vm._s(_vm.currentMenu.description) + " "),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "clase-view",
                      on: {
                        click: function ($event) {
                          _vm.visible = false
                        },
                      },
                    },
                    [
                      _c("a-icon", {
                        staticClass: "close-icon",
                        attrs: { type: "close" },
                      }),
                    ],
                    1
                  ),
                ]),
                _c(
                  "transition-group",
                  {
                    staticClass: "card-list dont-copy",
                    attrs: {
                      id: "cardList",
                      tag: "div",
                      name: "fade-list",
                      css: false,
                    },
                    on: {
                      "before-enter": _vm.beforeEnter,
                      enter: _vm.enter,
                      "after-enter": _vm.afterEnter,
                    },
                  },
                  _vm._l(_vm.currentCardList, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: item.cardCode,
                        staticClass: "card-item",
                        class: {
                          "card-item-disabled": item.disabled,
                          "card-item-active":
                            item.cardCode === _vm.currentCardCode,
                        },
                        attrs: { "data-delay": index * 100 },
                        on: {
                          click: function ($event) {
                            return _vm.goPage(item)
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "card-name" }, [
                          _c("div", { staticClass: "card-name-text" }, [
                            _c("div", { staticClass: "sort-num" }, [
                              _vm._v(" " + _vm._s(item.sortNum) + " "),
                            ]),
                            _c("div", { staticClass: "card-title" }, [
                              _vm._v(" " + _vm._s(item.title) + " "),
                            ]),
                          ]),
                          !item.checkStatus
                            ? _c("div", { staticClass: "sort-warnning" }, [
                                _vm._v(" ! "),
                              ])
                            : _vm._e(),
                          item.optional
                            ? _c("div", { staticClass: "sort-success" }, [
                                _vm._v(" 可略过 "),
                              ])
                            : _vm._e(),
                        ]),
                        item.subTitle && item.subTitle.length > 16
                          ? [
                              _c(
                                "a-tooltip",
                                {
                                  attrs: {
                                    trigger: "hover",
                                    "overlay-class-name": "sub-title-tooltip",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { attrs: { slot: "title" }, slot: "title" },
                                    [
                                      _c("div", {
                                        domProps: {
                                          innerHTML: _vm._s(item.subTitle),
                                        },
                                      }),
                                    ]
                                  ),
                                  _c("div", { staticClass: "sub-title" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("replaceBr")(item.subTitle)
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          : [
                              _c("div", { staticClass: "sub-title" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm._f("replaceBr")(item.subTitle)) +
                                    " "
                                ),
                              ]),
                            ],
                        _c(
                          "div",
                          { staticClass: "step-list" },
                          _vm._l(item.cardStepList, function (step) {
                            return _c(
                              "div",
                              {
                                key: step.stepCode,
                                class: _vm.link ? "step-item2" : "step-item",
                              },
                              [
                                _vm.link
                                  ? _c("div", { staticClass: "stepImg" }, [
                                      _c("img", {
                                        attrs: { src: _vm.excelImg, alt: "" },
                                      }),
                                    ])
                                  : _vm._e(),
                                _c("div", { staticClass: "step-name" }, [
                                  _vm._v(" " + _vm._s(step.stepName) + " "),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass: "check-value",
                                    class: {
                                      "check-status": !step.checkStatus,
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(step.checkValue) + " ")]
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                      ],
                      2
                    )
                  }),
                  0
                ),
              ],
              1
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }