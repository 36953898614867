var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "menu" },
    [
      _c(
        "ul",
        { staticClass: "menuBox" },
        [
          _vm._l(_vm.openMenu, function (key, index) {
            return [
              _vm.showMenu(key.fullPath)
                ? _c(
                    "li",
                    {
                      key: "menuItem" + index,
                      staticClass: "menuItem",
                      class: {
                        active: _vm.$route.matched[1].path === key.fullPath,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.changeMenu(key, false, index)
                        },
                      },
                    },
                    [
                      _c("svg", { staticClass: "iconpark-icon" }, [
                        _c("use", {
                          attrs: {
                            href: _vm.menuIcon(key.fullPath, key.meta.icon),
                          },
                        }),
                      ]),
                      _c("div", { staticClass: "menuName" }, [
                        _vm._v(_vm._s(key.meta.subName)),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
      _vm.globalAuther
        ? _c(
            "div",
            { staticClass: "bottomMune" },
            [
              _c(
                "a-tooltip",
                { attrs: { placement: "right" } },
                [
                  _c("template", { slot: "title" }, [
                    _vm._v(
                      " " + _vm._s(_vm.globalRouteInfo.meta.subName) + " "
                    ),
                  ]),
                  _c("span", [
                    _c(
                      "svg",
                      {
                        staticClass: "iconpark-icon globalSetting",
                        on: { click: _vm.toGlobal },
                      },
                      [
                        _c("use", {
                          attrs: { href: _vm.globalRouteInfo.meta.icon },
                        }),
                      ]
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.foldMenu.length > 0
        ? _c(
            "a-popover",
            {
              attrs: {
                "overlay-class-name": "aaa",
                title: "",
                trigger: "click",
                placement: "rightTop",
              },
              model: {
                value: _vm.visible,
                callback: function ($$v) {
                  _vm.visible = $$v
                },
                expression: "visible",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "foldMenuBox",
                  attrs: { slot: "content" },
                  slot: "content",
                },
                [
                  _c(
                    "ul",
                    { staticClass: "foldMenuBox" },
                    _vm._l(_vm.foldMenu, function (key, index) {
                      return _c(
                        "li",
                        {
                          key: "foldMenuItem" + index,
                          staticClass: "foldMenuItem",
                          class: {
                            active: _vm.$route.matched[1].path === key.fullPath,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.changeMenu(key, true, index)
                            },
                          },
                        },
                        [
                          _c(
                            "svg",
                            { staticClass: "iconpark-icon foldMenuIcon" },
                            [_c("use", { attrs: { href: "" + key.meta.icon } })]
                          ),
                          _c("span", { staticClass: "foldMenuName" }, [
                            _vm._v(_vm._s(key.meta.subName)),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              ),
              _c("div", { staticClass: "hideMenu" }, [_vm._v(" ... ")]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }